import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import Particles from 'react-tsparticles';
import particlesConfig from './config/configParticles';
import image from "./background2.gif";
import bgimg from "./bg-title.png";

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 1em;
  margin-left: 6em;
  margin-right: 6em;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: minmax(25px, auto);
`;

export const Home = (props) => (
	<div style={{ backgroundImage:`url(${image})`, backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh' }}>
		
		<Container>
		  <Row>
			<Col> <p>  </p></Col>
			<Col xs={9} style={{backgroundImage:`url(${bgimg})`}}> 
				<h1 style={{ color: 'white' }}>ARDUONS COMPANY</h1>
				<h1 style={{ color: 'white' }}></h1>
				<p style={{ color: 'white' }}> Arduons cuenta con un equipo multidisciplinar con amplia experiencia en electrónica, programación, diseño, etc. Damos solución a las necesidades
				que el cliente tenga en materia de sistemas electrónicos de control, monitorización, alimentación, digitalización, ... 
				</p>
				<p style={{ color: 'white' }}> Nuestros colaboradores han podido ayudar a multitud de empresas con ideas de negocio, actualización de productos, mejora de productos, etc.
				</p>
				<p style={{ color: 'white' }}> Ofrecemos planes flexibles y económicos por nuestros servicios. Tanto si eres una empresa joven, una empresa que no tiene una idea clara
				de lo que necesita desarrollar o un particular con una idea de negocio, puedes consultarnos.  Te asesoraremos sin compromiso.
				</p>
				<p> 
				</p>
			</Col>
			<Col> <p>  </p></Col>
		  </Row>
		</Container>

	</div>
)

/*
		<GridWrapper>
			
		</GridWrapper>

<div className="App" style={{ position: 'relative', overflow: "hidden" }}>
		<div style={{ position: 'absolute'}}>
		  <Particles height="100vh" width="100vw" params={particlesConfig} />
		</div>
</div>
*/

/*
const GridWrapper3 = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 1em;
  margin-left: 6em;
  margin-right: 6em;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(25px, auto);
`;*/

/*
const GridWrapper2 = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 1em;
  margin-left: 6em;
  margin-right: 6em;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(25px, auto);
`;*/