import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import styled from 'styled-components';
import myimage from './ultracapacitor.png';
import myimage2 from './agrotech.png';
import myimage3 from './obsoleto.png';
import myimage4 from './igbt.png';
import myimage5 from './BLE.png';
import bgimg from "./bg-title3.png";

import ToggleBox from "./components/ToggleBox";
import { BMS, Agrotech, Obsoles, ElecPot, BLE } from "./components/DescriProyectos";

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 1em;
  margin-left: 6em;
  margin-right: 6em;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: minmax(25px, auto);
`;

const GridWrapper2 = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 1em;
  margin-left: 6em;
  margin-right: 6em;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(25px, auto);
`;

// Styling a regular HTML input
const StyledInput = styled.input`
  display: block;
  margin: 20px 0px;
  border: 1px solid lightblue;
`;


export default class Proyectos extends React.Component {

  
  render() {

	  
    return (
		<Container>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row style={{backgroundImage:`url(${bgimg})`}}>
			<Col xs={1}></Col>
			<Col>
				<img src={myimage}
				alt="Arduonsa BMS UCMS" 
				height={200}
				 />
			</Col>
			<Col xs={5}>
				<h2>BMS & UCMS</h2>
				<p>Desarrollo del FW de control para un BMS y un UCMS.</p>
				<ToggleBox title="Show BMS description">
					<BMS />
				</ToggleBox> 
				
			</Col>
			<Col></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row style={{backgroundImage:`url(${bgimg})`}}>
			<Col xs={1}></Col>
			<Col>
				<img src={myimage2}
				alt="Arduonsa agrotech agricultura electronica" 
				height={200}
				 />
			</Col>
			<Col xs={5}>
				<h2>Sensorización de plantaciones</h2>
				<p>Investigación y desarrollo de sistema de monitorización de plantaciones.</p>
				<ToggleBox title="Show agrotech description">
					<Agrotech />
				</ToggleBox> 
			</Col>
			<Col></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row style={{backgroundImage:`url(${bgimg})`}}>
			<Col xs={1}></Col>
			<Col>
				<img src={myimage3}
				alt="Arduonsa electronica obsoleta obsolescencia" 
				height={180}
				 />
			</Col>
			<Col xs={5}>
				<h2>Resolución de obsolescencias</h2>
				<p>Resolución de problemas derivados por la obsolescencia de equipos de control y medida.</p>
				<ToggleBox title="Show obsolescencia description">
					<Obsoles />
				</ToggleBox> 
			</Col>
			<Col></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		   <Row style={{backgroundImage:`url(${bgimg})`}}>
			<Col xs={1}></Col>
			<Col>
				<img src={myimage4}
				alt="Arduonsa PSCAD Simulink" 
				height={200}
				 />
			</Col>
			<Col xs={5}>
				<h2>Consultoría electrónica de potencia</h2>
				<p>Diseño y simulación de sistemas electrónicos de potencia (Inversores, DC-DC, B2B, HVDC, Statcom, etc).</p>
				<ToggleBox title="Show ElecPot description">
					<ElecPot />
				</ToggleBox> 
			</Col>
			<Col></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row style={{backgroundImage:`url(${bgimg})`}}>
			<Col xs={1}></Col>
			<Col>
				<img src={myimage5}
				alt="Arduonsa BLE geoposicionamiento APK" 
				height={250}
				 />
			</Col>
			<Col xs={5}>
				<h2>Sistema de posicionamiento por BLE</h2>
				<p>Dispositivo IoT para posicionamiento y sensorización que comunica con plataforma portable a través de Bluetooth Low Energy.</p>
				<ToggleBox title="Show BLE description">
					<BLE />
				</ToggleBox> 
			</Col>
			<Col></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		</Container>
    );
  }
}


/*
<div >
				<h6>COMM </h6>
				<StyledInput
					
					placeholder="COM2"
				  />
			</div>
*/


/*<p>En colaboración con importante empresa del sector del almacenamiento híbrido de energía eléctrica hemos desarrollado un 
				sistema de control de baterías y/o supercondensadores. En base a un integrado de AD, se diseñó una estructura de monitorización
				y control de hasta 480 celdas de batería o SC. Todos los integrados reportan los valores de tensión a un DSP de TI el cual, 
				además de otras funciones, controla qué celda/s hay que poner a descargar para realizar el equilibrado.</p>
				<p>También colaboramos en la integración de los DSPs con microcontroladores con arquitectura ARM y Linux para poder realizar la
				monitorización del sistema a alto nivel y la integración con las demás partes del mismo.
				</p>*/