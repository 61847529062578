import React from "react";
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
} from "./FooterStyles";
import { Nav, Navbar, Form, FormControl } from 'react-bootstrap';
import styled from 'styled-components';
import mylogo from './logo.png';

/*
const Footer = () => {
  return (
    <Box>
      <h1 style={{ color: "green", 
                   textAlign: "center", 
                   marginTop: "-50px" }}>
        GeeksforGeeks: A Computer Science Portal for Geeks
      </h1>
      <Container>
        <Row>
          <Column>
            <Heading>About</Heading>
          </Column>
          <Column>
            <Heading>Services</Heading>
          </Column> 
        </Row>
      </Container>
    </Box>
  );
};
export default Footer;
*/

const Styles = styled.div`
  .navbar { background-color: #2F2D2F; }
  a, .navbar-nav, .navbar-light .nav-link {
    color: #FFFFFF;
	position: absolute;
	left:0;
	bottom:0;
	right:0;
	bottom: 0;
	width: 100%;
    &:hover { color: white; }
  }
  .navbar-brand {
    font-size: 1.4em;
    color: #FFFFFF;
    &:hover { color: white; }
  }
  .form-center {
    position: absolute !important;
    left: 60%;
    right: 10%;
  }
`;
/*
const Footer = () => {
  return (
    <Styles>
      <h1 style={{ color: "green", 
                   textAlign: "center", 
                   marginTop: "-50px" }}>
        GeeksforGeeks: A Computer Science Portal for Geeks
      </h1>
    </Styles>
  );
};
*/



const Footer = () => (
  <footer className="footer">
    <p>© 2022 Arduons Company  .</p>
	
  </footer>
);
 


export default Footer;