import React, { Component } from "react";

class BMS extends Component {	 
	render() {
		return (
			<div> 
				<p>En colaboración con importante empresa del sector del almacenamiento híbrido de energía eléctrica hemos desarrollado un 
				sistema de control de baterías y/o supercondensadores. En base a un integrado de AD, se diseñó una estructura de monitorización
				y control de hasta 480 celdas de batería o SC. Todos los integrados reportan los valores de tensión a un DSP de TI el cual, 
				además de otras funciones, controla qué celda/s hay que poner a descargar para realizar el equilibrado.</p>
				<p>También colaboramos en la integración de los DSPs con microcontroladores con arquitectura ARM y Linux para poder realizar la
				monitorización del sistema a alto nivel y la integración con las demás partes del mismo.
				</p>
			</div>
		);
	};
}

class Agrotech extends Component {	 
	render() {
		return (
			<div> 
				<p>Investigación y desarrollo de sistema para monitorizar la madurez de fruta climatérica. Dicho tipo de fruta genera etanol
				durante su maduración. Monitorizando los niveles de etanol de la plantación se puede conocer el grado de madurez de la fruta.
				</p>
				<p>Se está desarrollando y probando en campo una red de sensores interconectados junto al sistema maestro que recoge todos los
				datos y los dispone para luego ser tratados y evaluados.
				</p>
			</div>
		);
	};
}

class Obsoles extends Component {	 
	render() {
		return (
			<div> 
				<p>Varias empresas se han visto afectadas por la continua evolución de la electrónica, así como de los distintos dispositivos
				de medida y control que ofrecen los fabricantes. A este respecto les hemos ayudado a solventar los problemas que les han surgido
				adaptando soluciones actuales a sistemas antiguos o realizando interfaces que permitan seguir haciendo uso de esos sistemas.</p>
				<p>Hemos ayudado a una empresa con memorias industriales que estaban obsoletas. Para ello, hemos desarrollado interfaces entre buses. 
				Esto ha permitido poder adaptar memorias más actuales al sistema con el cuentan.
				</p>
				<p>Del mismo modo, se han adaptado nuevos sistemas de medidas (tales como osciloscopios) a sistemas de producción antiguos. Para ello,
				se han desarrollado interfaces que traducen entre los comandos de los sistemas de medida originales y los nuevos, de forma que el sistema
				 pueda seguir trabajando sin verse afectado por el cambio.
				</p>
			</div>
		);
	};
}

class ElecPot extends Component {	 
	render() {
		return (
			<div> 
				<p>Hemos podido asesorar a varios clientes en materia de electrónica de potencia. Se trata de clientes con una idea de negocio y que 
				necesitan conocer la viabilidad antes de realizar una fuerte inversión. Con nuestro asesoramiento y los resultados obtenidos en diseños
				 preliminares y simulaciones se ha podido ayudar al cliente en la toma de decisión sobre la viabilidad del producto.</p>
			</div>
		);
	};
}

class BLE extends Component {	 
	render() {
		return (
			<div> 
				<p>Colaboramos con un cliente que desarrolló un sistema de geoposicionamiento. Este sistema se instala en cualquier elemento (coche, tren, 
				container, etc) y, a través, de BLE transfiere los datos a otro sistema que cuenta con una Apk donde lo registra en una base de datos y 
				lo pone a disposición del usuario.</p>
			</div>
		);
	};
}

export { BMS, Agrotech, Obsoles, ElecPot, BLE };

/*
class BMS {

  area(){
    return this.length*this.width;
  }
}

class Triangle {
  constructor(base, height){
    this.base = base;
    this.height = height;
  }

  area(){
    return (this.base*this.height)/2;
  }
}

export Rectangle;*/