import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import styled from 'styled-components';
import myimage from './elementos-circuito-electrico.png';
import myimage2 from './FPGA.png';
import myimage3 from './Firmware.png';
import myimage4 from './simulacion.png';
import myimage5 from './elecRepair.png';
import bgimg from "./bg-title3.png";


/*
export const Currents = () => (
  <GridWrapper>
    <h2>Current Measures</h2>
    <p>Here, you can see the current value of diferent currents.</p>
  </GridWrapper>
)*/

export class Servicios extends React.Component {

  
  render() {
	    
    return (

		<Container>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row style={{backgroundImage:`url(${bgimg})`}}>
			<Col xs={1}></Col>
			<Col>
				<img src={myimage}
				alt="Arduons electrónica" 
				height={150}
				 />
			</Col>
			<Col xs={5}>
				<h2>Diseño HW electrónico</h2>
				<p>Electrónica analógica, digital y de potencia.</p>
			</Col>
			<Col></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row style={{backgroundImage:`url(${bgimg})`}}>
			<Col xs={1}></Col>
			<Col>
				<img src={myimage2}
				alt="Arduons FPGA" 
				height={150}
				 />
			</Col>
			<Col xs={5}>
				<h2>Desarollos con FPGAs</h2>
				<p>Experiencia en el desarrollo de aplicaciones con FPGAs en VHDL y con fabricantes como Xilinx o Altera.</p>
			</Col>
			<Col></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row style={{backgroundImage:`url(${bgimg})`}}>
			<Col xs={1}></Col>
			<Col>
				<img src={myimage3}
				alt="Arduons FW SW" 
				height={150}
				justifyContent= "left"
				 />
			</Col>
			<Col xs={5}>
				<h2>Programación FW y SW</h2>
				<p>Experiencia en el desarrollo de aplicaciones embebidas: C, C++, Python, etc.</p>
				<p></p>
				<p>También hemos realizado interfaces de usuario para PC en diferentes plataformas de programación: C++, Python, Labview, React, Nodejs, etc.</p>
			</Col>
			<Col></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		   <Row style={{backgroundImage:`url(${bgimg})`}}>
			<Col xs={1}></Col>
			<Col>
				<img src={myimage4}
				alt="Arduons PSCAD Simulink" 
				height={150}
				 />
			</Col>
			<Col xs={5}>
				<h2>Simulación de sistemas electrónicos de potencia</h2>
				<p>Experiencia en simulaciones de sistemas electrónicos de potencia con PSCAD y Matlab/Simulink.</p>
			</Col>
			<Col></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row style={{backgroundImage:`url(${bgimg})`}}>
			<Col xs={1}></Col>
			<Col>
				<img src={myimage5}
				alt="Arduons mantenimiento reparacion" 
				height={150}
				 />
			</Col>
			<Col xs={5}>
				<h2>Mantenimiento y reparación de sistemas electrónicos</h2>
				<p>Realizamos mantenimiento y reparación de sistemas electrónicos de los que disponga. Bien por obsolescencia o por falta de soporte del fabricante</p>
			</Col>
			<Col></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		  <Row>
			<Col> <p>  </p> </Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
			<Col> <p>  </p></Col>
		  </Row>
		</Container>
    );
  }
}

/*




	<ul>
			  {Values.map(data => (
				<li key={data.id}> 
				<GridWrapper2>
					<p>{data.name}:</p>
					<p>{data.value}</p>
					<p> A</p>
				</GridWrapper2>
				</li>
			  ))}
			</ul>
			
			
			const Values = [
			{ id: 1, name: 'DC Current', value: '20.0' },
			{ id: 2, name: 'AC 1' , value: '3.2'},
			{ id: 3, name: 'AC 2' , value: '3.4'},
			{ id: 4, name: 'AC 3' , value: '2.9'}
		  ];

*/