//import logo from './logo.svg';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { NavigationBar } from './components/NavigationBar';
import React from "react";
import { Home } from './pages/home';
import { About } from './pages/about'; 
import { Servicios } from './pages/servicios';
import  Proyectos  from './pages/proyectos';
import { NoMatch } from './pages/nomatch';
import Footer from './components/Footer';
//import Sidebar from './components/Sidebar';

function App() {
	
	
  return (
   
	<React.Fragment>
	  <Router>
		<NavigationBar />
		<Switch>
		  <Route exact path="/" component={Home} />
		  <Route path="/servicios" component={Servicios} />
		  <Route path="/proyectos" component={Proyectos} />
		  <Route path="/about" component={About} />
		  <Route component={NoMatch} />
		</Switch>
		<Footer />
	  </Router>
	</React.Fragment>
  );
}

export default App;

//<Sidebar />