import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { Button} from 'react-bootstrap';
import { ContactForm } from "./components/ContactForm";
import mylogo from './logo.png';

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 1em;
  margin-left: 6em;
  margin-right: 6em;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(25px, auto);
`; 


export const About = () => (

  <Container>
	  <Row>
		<Col> <p>  </p> </Col>
		<Col> <p>  </p></Col>
		<Col> <p>  </p></Col>
		<Col> <p>  </p></Col>
	  </Row>
	  <Row>
	    <Col> <p>  </p> </Col>
		<Col> <p>  </p></Col>
		<Col> <p>  </p></Col>
		<Col> <p>  </p></Col>
	  </Row>
	  <Row>
		<Col> 
			<h2>ARDUONS Company</h2>
			<p> </p>
			
			<img src={mylogo}  
					alt="arduonsa arduon" 
					height={250}
					 />
			<p> </p>
			<p>Alcalá de Guadaíra, Sevilla, España </p>
			<p>info@arduons.com </p>
		</Col>
		<Col> 
			<h3>CONTACTO</h3>
			<ContactForm></ContactForm>
		</Col>
	  </Row>
	  <Row>
		<Col> <p>  </p> </Col>
		<Col> <p>  </p></Col>
		<Col> <p>  </p></Col>
		<Col> <p>  </p></Col>
	  </Row>
	  <Row>
	    <Col> 

		</Col>
		<Col> <p>  </p></Col>
		<Col> <p>  </p></Col>
		<Col> <p>  </p></Col>
	  </Row>
  </Container>

)


// <TextField label="Full Name" className="w-61" autocomplete="none"/>
/*
    <form>
      <TextField label="Full Name" fullWidth autocomplete="none"/>
      <TextField label="Email" fullWidth autocomplete="none"/>
      <TextField label="Message" fullWidth multiline rows={5} autocomplete="none"/>
      <Button type="submit">Submit</Button>
    </form>
	
	  <GridWrapper>
    

  </GridWrapper>
*/